import { attach, createEffect } from "effector";
import Pusher from "pusher-js";

import { TFFRequestWithMetaData } from "../../types/common/entities/common";
import { TSearchPayload } from "../../types/search";
import { TTourSearchResponse } from "../../types/tours";

import { DEV_BASE_URL } from "@/shared/config";
import { requestFx } from "@/shared/config";
import { EVENT_NAME } from "@/shared/lib/constants";

export type TTourSearchResponseNEW = TFFRequestWithMetaData<
  TTourSearchResponse["data"],
  TTourSearchResponse["meta"]
>;

export const fetchInitialSearchFx = attach<
  TSearchPayload,
  TTourSearchResponseNEW
>({
  effect: requestFx,
  mapParams: ({ data, params }) => ({
    data,
    params,
    method: "post",
    url: `tours/search`,
  }),
});

export const fetchActualizedSearchFx = attach<
  TSearchPayload & { id: string },
  TTourSearchResponseNEW
>({
  effect: requestFx,
  mapParams: ({ id, data, params }) => {
    return {
      data,
      params,
      method: "post",
      url: `tours/search/${id}`,
    };
  },
});

export const fetchResponseFromWsFx = createEffect({
  name: "fetchResponseFromWsFx",
  handler: async (queryId: string) => {
    const client = new Pusher("packages", {
      wsPort: 6010,
      forceTLS: false,
      disableStats: true,
      enabledTransports: ["ws", "wss"],
      wsHost: DEV_BASE_URL?.split("//")[1],
    });

    const channelName = `tour-search.${queryId}`;

    const awaitingdWsResponse = () => {
      return new Promise<void>((resolve) => {
        client.subscribe(channelName);

        client
          .channel(channelName)
          .bind(
            EVENT_NAME,
            (message: { data?: { search_completed?: boolean } }) => {
              if (message.data?.search_completed) {
                resolve();

                client.disconnect();
              }
            },
          );
      });
    };

    await awaitingdWsResponse();

    client.unsubscribe(channelName);

    return { data: { id: queryId, completed: true } };
  },
});
