import {
  TMeal,
  TRoom,
  TAirline,
  TCountry,
  TSegment,
  EService,
  TTourist,
  TDiscount,
  TLocation,
  THotelData,
  TCurrencies,
  BACKEND_KEYS,
  EFlightTypes,
  TPaymentPlan,
  TCountriesTags,
  TFlightListItem,
  TCharterFlightListItem,
} from "../../common";

type TTourOperator = {
  name: string;
  icon: string;
};

type TTourgroup = {
  id: string;
  name: string;
  tour_content: string[];
};

type TPagination = {
  count: number;
  total: number;
  per_page: number;
  total_pages: number;
  current_page: number;
};

export type TFavouriteTourItem = {
  id: string;
  price: number;
  adults: number;
  nights: number;
  infants: number;
  expired: boolean;
  children: number;
  check_in: string;
  check_out: string;
  hotel: THotelData;
  currency: TCurrencies;
  tour_group: TTourgroup;
  arrival_country_id: string;
  arrival_country: TLocation;
  departure_location: TLocation;
  arrival_location_id: null | string;
};

export enum EServiceTypes {
  Transfer = "трансфер",
  // TODO: waiting for backend
  // Insurance = "страховка",
  ChildSeat = "детское кресло",
  IndividualTransfer = "индивидуальный трансфер",
}

export type TService = {
  id: string;
  name: string;
  price: string;
  code: EService;
  date_to: string;
  group_id: string;
  required: boolean;
  price_pax: number;
  personal: boolean;
  date_from: string;
  removable: boolean;
  isChecked?: boolean;
  passengers?: number[];
  currency: TCurrencies;
  service_type_name: EServiceTypes;
};

export type TTransport = {
  id: string;
  markup: string;
  group_id: string;
  segments: TSegment[];
  currency: TCurrencies;
};

type TOptions = {
  services: TService[];
  transports: TTransport[] | TCharterFlightListItem[];
};

export type TAadditionalTourItemFields = {
  date_to: string;
  date_from: string;
};

export type TTourItem = {
  id: string;
  meal: TMeal;
  room: TRoom;
  price: number;
  adults: number;
  nights: number;
  infants: number;
  check_in: string;
  expired: boolean;
  children?: number;
  hotel: THotelData;
  check_out: string;
  options?: TOptions;
  full_price: number;
  services: TService[];
  nights_total: number;
  airlines: TAirline[];
  currency: TCurrencies;
  bonus_amount?: number;
  tour_group: TTourgroup;
  discounts: TDiscount[];
  tags: TCountriesTags[];
  moment_confirm: boolean;
  flight_type: EFlightTypes;
  itinerary: TFlightListItem;
  tour_operator: TTourOperator;
  payment_plan: null | TPaymentPlan;
};

export type TFavoriteTours = {
  pagination: TPagination;
  items: TFavouriteTourItem[];
};

type TBookingRefs = {
  sex: TCountry[];
  country: TCountry[];
  payment_type: TCountry[];
  document_type: TCountry[];
};

type TPassengersRule = {
  rule: string;
  message: string;
  required: boolean;
};

type TPassengersRef = {
  ref: string;
  message: string;
  required: boolean;
};

type TBookingFields = {
  payment_type_id: TPassengersRef;
  "passengers.sex": TPassengersRef;
  "passengers.birthdate": TPassengersRule;
  "passengers.last_name": TPassengersRule;
  "passengers.first_name": TPassengersRule;
  "passengers.document_type": TPassengersRef;
  "passengers.document_given": TPassengersRule;
  "passengers.document_expire": TPassengersRule;
  "passengers.document_issued": TPassengersRule;
  "passengers.document_number": TPassengersRule;
  "passengers.citizenship_country_id": TPassengersRef;
};

export type TBookingInfo = {
  refs: TBookingRefs;
  fields: TBookingFields;
};

export type TOrderData = {
  iin?: string;
  package_id: string;
  promo_code?: string;
  passengers: TTourist[];
  payment_type_id: string;
  additonal_email: string;
};

// TODO: delete after finishing effector refactor
export type TPromoCodeRequestData = {
  code: string;
  packageId: string;
};

export type TPromoCodeResponseData = {
  code: string;
  price: number;
  currency: string;
  full_price: number;
  discount: TDiscount;
};

export type TBookWithManager = {
  name: string;
  phone: string;
  package_id: string;
  [BACKEND_KEYS.childrenAges]?: string[];
};

export type TFilters = {
  id: string;
  name: string;
  code: string;
  options: {
    id: string;
    name: string;
    code: string;
    value: string;
  }[];
};

export type TPrices = {
  min: number;
  max: number;
  avg: number;
};

type TMetaData = {
  query_id?: string;
  completed?: boolean;
  filters?: TFilters[];
  hotels_total?: number;
  prices?: null | TPrices;
  packages_total?: number;
};

export type TTourSearchResponse = {
  meta: TMetaData;
  data: TTourItem[];
};

export enum EFilterType {
  Room = "room",
  Meal = "meal",
}

export type TChangeValue = {
  type: EFilterType;
  value: string | number;
};

export type TFilterOptions = {
  value: string;
  label: string;
};

export type TTags = {
  id: string;
  sort: number;
  name: string;
  code: string;
  active: boolean;
  meta: {
    icon: string;
    bg_color: string;
    font_color: string;
  };
};

export type THotTourItem = {
  date: string;
  tags: TTags[];
  price: number;
  cover: string;
  nights: number;
  adults: number;
  active: boolean;
  no_visa: boolean;
  currency: string;
  children: number;
  price_pax: number;
  directly: boolean;
  arrival_country: {
    code: string;
    name: string;
  };
  departure_location: {
    code: string;
    name: string;
  };
  arrival_location: {
    id: null | string;
    name: null | string;
  };
};

export type TPromoCodePayload = {
  code: string;
  packageId: string;
};

export type TEditorSearchResponse = {
  beginDateTo: string;
  results: TTourItem[];
  actualized_at: string;
  beginDateFrom: string;
  arrivalCountryCode?: string;
  departureLocationCode?: string;
  arrivalLocationId?: string | string[];
};
