import { createQuery, createMutation } from "@farfetched/core";

import {
  postLeadToCRMFx,
  getOfficesListFx,
  getSeoMetaTagsFx,
  getHeadingCaptionsFx,
  getSelectionDetailsFx,
  getCountriesDetailsByIdFx,
} from "./endpoints";

import { QueryTools } from "@/shared/lib/helpers/farfetched";

export const getOfficesListQuery = createQuery({
  effect: getOfficesListFx,
  name: "getOfficesListQuery",
  mapData: ({ result }) => result.data.data,
});

export const getCountriesDetailsByIdQuery = createQuery({
  effect: getCountriesDetailsByIdFx,
  name: "getCountriesDetailsByIdQuery",
  mapData: ({ result }) => result.data?.data,
});

export const getSeoMetaTagsQuery = createQuery({
  effect: getSeoMetaTagsFx,
  name: "getSeoMetaTagsQuery",
  mapData: ({ result }) => result.data.data,
});

export const postLeadToCRMMutation = createMutation({
  effect: postLeadToCRMFx,
  name: "postLeadToCRMMutation",
});

export const getSelectionQuery = createQuery({
  name: "getSelectionQuery",
  effect: getSelectionDetailsFx,
  mapData: ({ result }) => result.data.data,
});

export const getHeadingCaptionsQuery = createQuery({
  effect: getHeadingCaptionsFx,
  name: "getHeadingCaptionsQuery",
  mapData: ({ result }) => result.data.data,
});

QueryTools(getOfficesListQuery).errors.handleAll();
QueryTools(getCountriesDetailsByIdQuery).errors.handleAll();
QueryTools(getSeoMetaTagsQuery).errors.handleAll();
QueryTools(getSelectionQuery).errors.handleAll();
