import { createQuery } from "@farfetched/core";

import {
  fetchInitialSearchFx,
  fetchResponseFromWsFx,
  fetchActualizedSearchFx,
} from "./endpoints";

import { QueryTools } from "@/shared/lib/helpers/farfetched";

export const getInitialSearchQuery = createQuery({
  name: "fetchInitialSearch",
  effect: fetchInitialSearchFx,
  mapData: ({ result }) => {
    return result.data;
  },
});

export const getActualizedSearchQuery = createQuery({
  name: "fetchActualizedSearch",
  effect: fetchActualizedSearchFx,
  mapData: ({ result }) => {
    return result.data;
  },
});

export const getActualizedSearchQueryWithWs = createQuery({
  name: "getResponseFromWs",
  effect: fetchResponseFromWsFx,
  mapData: ({ result }) => {
    return result.data;
  },
});

QueryTools(getInitialSearchQuery).errors.handleAll();
QueryTools(getActualizedSearchQuery).errors.handleAll();
QueryTools(getActualizedSearchQueryWithWs).errors.handleAll();
