import { THotel, TMedia, THotelData, TResponsiveImages } from "../../common";
import { TEditorSearchResponse } from "../../tours";

export enum EPageCategories {
  Visa = "visa",
  Tours = "tour",
  Promo = "promo",
  Cities = "city",
  Articles = "articles",
  HotTours = "hot_tour",
}

export enum EPageStatuses {
  Draft = "draft",
  Archive = "archive",
  Published = "published",
}

export enum EListTypes {
  Ordered = "ordered",
  Unordered = "unordered",
}

export enum EBlocks {
  List = "list",
  Table = "table",
  Image = "image",
  Tours = "tours",
  AiText = "aiText",
  Header = "header",
  Hotels = "hotels",
  Search = "search",
  Link = "customLink",
  Subtitle = "subtitle",
  Contacts = "contacts",
  YouTube = "youtubeLink",
  Delimiter = "delimiter",
  Paragraph = "paragraph",
  Accordion = "accordion",
}

export enum EParserCategory {
  Visa = "visa",
  Tours = "tours",
  Article = "article",
  Promotion = "promotion",
}

export type TStorageUrl = {
  storageUrl: string;
};

export type TAnchorProp = {
  anchor: string;
  withAnchor?: boolean;
};

export type TBreadcrumb = {
  href: string;
  title: string;
};

export type TNavLink = {
  title: string;
  anchor: string;
  type: EBlocks.Header | EBlocks.Accordion;
};

type TNoPropComponent<K> = {
  type: K;
  data: any;
  id: string;
};

type TCommonBlock<K, T> = {
  data: T;
  type: K;
  id: string;
};

export type TParagraphData = {
  text: string;
  fontSize?: null | number;
};

export type TYoutubeData = {
  url: string;
};

export type THeadingLevels = 1 | 2 | 3 | 4;

export type THeadingData = {
  text: string;
  level: THeadingLevels;
  fontSize: null | number;
};

export type TSubtitleData = {
  text: string;
  fontSize?: null | number;
};

export type TListData = {
  items: string[];
  style: EListTypes;
};

export type TTableData = {
  content: string[][];
  withHeadings: boolean;
};

export type TAccordionData = {
  title: string;
  content: TBlocks;
  fontSize?: null | number;
};

export type THotelBlockData = {
  hotels: THotel[];
};

export type TLinkData = {
  href: string;
  title: string;
};

export type TGalleryData = {
  images: (TMedia | TResponsiveImages)[];
  croppedImages?: (TMedia | TResponsiveImages)[];
};

export type TConstructorPageCategory = {
  id: string;
  name: string;
  slug: EPageCategories;
};

export type TNavbarElement = {
  id: string;
  name: string;
  url: null | string;
  departure_location: {
    id: string;
    name: string;
  };
};

export type TParagraph = TCommonBlock<EBlocks.Paragraph, TParagraphData>;
export type THeading = TCommonBlock<EBlocks.Header, THeadingData>;
export type TSubtitle = TCommonBlock<EBlocks.Subtitle, TSubtitleData>;
export type TList = TCommonBlock<EBlocks.List, TListData>;
export type TImage = TCommonBlock<EBlocks.Image, TGalleryData>;
export type TTable = TCommonBlock<EBlocks.Table, TTableData>;
export type TAccordion = TCommonBlock<EBlocks.Accordion, TAccordionData>;
export type THotels = TCommonBlock<EBlocks.Hotels, THotelBlockData>;
export type TLink = TCommonBlock<EBlocks.Link, TLinkData>;
export type TSearch = TCommonBlock<EBlocks.Search, TEditorSearchResponse>;
export type TYouTube = TCommonBlock<EBlocks.YouTube, TYoutubeData>;
export type TAiText = TCommonBlock<EBlocks.AiText, TParagraphData>;

//  no props
export type TDelimiter = TNoPropComponent<EBlocks.Delimiter>;
export type TContacts = TNoPropComponent<EBlocks.Contacts>;
export type TTours = TNoPropComponent<EBlocks.Tours>;

export type TConstructorBlocks =
  | TList
  | TLink
  | TTours
  | TTable
  | TImage
  | TAiText
  | THotels
  | TSearch
  | THeading
  | TYouTube
  | TSubtitle
  | TContacts
  | TDelimiter
  | TParagraph
  | TAccordion;

export type TBlocks = {
  time: number;
  blocks?: TConstructorBlocks[];
};

type TPreview = {
  id: string;
  image: string;
};

type TPreviewsDeviceType = {
  mobile: TPreview;
  desktop: TPreview;
};

export type TCroppedPreviews = {
  default: TPreviewsDeviceType;
  main_preview: TPreviewsDeviceType;
  list_preview: TPreviewsDeviceType;
  inner_preview: TPreviewsDeviceType;
};

export type TConstructorGeneralInfo = {
  id: string;
  url: string;
  name: string;
  active: boolean;
  editedBy: string;
  created_at: string;
  updated_at: string;
  storage_url: string;
  status: EPageStatuses;
  preview: null | string;
  previews: TCroppedPreviews;
  breadcrumbs: null | TBreadcrumb[];
  category: TConstructorPageCategory;
  author: {
    id: string;
    last_name: string;
    first_name: string;
  };
};

export type TDestination = {
  id: string;
  url: string;
  name: string;
  visa: boolean;
  price: number;
  currency: string;
  country: {
    id: string;
    code: string;
    name: string;
    cover: string;
  };
};

export type TPageArrivalCard = {
  id: string;
  url: string;
  name: string;
  price: number;
  visa: boolean;
  preview: string;
};

export type TToursArrivalsData = Omit<
  TConstructorGeneralInfo,
  "content" | "editedBy" | "storage_url"
> & {
  price: number;
  visa: boolean;
  country_name: string;
};

type TToursLocation = {
  id: string;
  code: string;
  name: string;
  cover?: string;
};

export type TToursDepartues = TToursLocation & {
  url: string;
  price: number;
  country: TToursLocation;
};

export type TPageCategory = {
  id: string;
  name: string;
  slug: EPageCategories;
};

export type TPageOptions = {
  page?: number;
  perPage?: number;
};

export type TAllDeparturesAndArrivals = {
  arrivals: TToursArrivalsData[];
} & TToursDepartues;

export type TArrivals = {
  id: string;
  name: string;
  url: null | string;
  country: {
    id: string;
    code: string;
    name: string;
    cover: string;
  };
};

export type THotTourData = {
  id: string;
  adults: number;
  nights: number;
  infants: number;
  children: number;
  hotel: THotelData;
  return_date: string;
  current_price: number;
  departure_date: string;
  departure_location: {
    id: string;
    name: string;
    code: string;
  };
  payment_plan: null | {
    amount: number;
    period: number;
    currency: string;
  };
};
