/* eslint-disable perfectionist/sort-objects */
import { sample, combine, createApi, createEvent, createStore } from "effector";
import uniqBy from "lodash/uniqBy";

import { getConstructorPageQuery } from "../../api/constructor";
import {
  getInitialSearchQuery,
  getActualizedSearchQuery,
} from "../../api/search";
import { EBlocks } from "../../types/constructor-parser";
import { TToursCombinedAsRooms } from "../../types/tour-content";
import {
  EFilterType,
  TChangeValue,
  TEditorSearchResponse,
} from "../../types/tours";

import { CHECKED_ALL } from "@/shared/lib/constants";

export const $searchHotelsQuery = createStore<null | string>(null);
export const $selectedHotel = createStore<null | string>(null);

export const { setSearchHotelsQuery, resetSearchHotelsQuery } = createApi(
  $searchHotelsQuery,
  {
    resetSearchHotelsQuery: () => null,
    setSearchHotelsQuery: (_, payload: string) => payload,
  },
);

export const { setSelectedHotel, resetSelectedHotel } = createApi(
  $selectedHotel,
  {
    resetSelectedHotel: () => null,
    setSelectedHotel: (_, id: string) => id,
  },
);

export const $toursData = combine(
  getInitialSearchQuery.$data,
  getActualizedSearchQuery.$data,
  (initData, actualizedData) => actualizedData || initData,
);

const toggleValue = (value: string | number, set: Set<string | number>) => {
  if (value === CHECKED_ALL) {
    return [CHECKED_ALL];
  }

  set.delete(CHECKED_ALL);

  if (set.has(value)) {
    set.delete(value);
  } else {
    set.add(value);
  }

  if (set.size === 0) {
    set.add(CHECKED_ALL);
  }

  return [...set];
};

export const $filtersByRoomType = createStore<(string | number)[]>([
  CHECKED_ALL,
]);
export const $filtersByMealType = createStore<(string | number)[]>([
  CHECKED_ALL,
]);

export const handleFilterChange = createEvent<TChangeValue>();
export const handleFiltersReset = createEvent<void | EFilterType>();

sample({
  clock: handleFilterChange,
  source: $filtersByRoomType,
  filter: (_, { type }) => type === EFilterType.Room,
  fn: (data, { value }) => {
    const newFiltersByRoomType = new Set(data);
    return toggleValue(value, newFiltersByRoomType);
  },
  target: $filtersByRoomType,
});

sample({
  clock: handleFilterChange,
  source: $filtersByMealType,
  filter: (_, { type }) => type === EFilterType.Meal,
  fn: (data, { value }) => {
    const newFiltersByMealType = new Set(data);
    return toggleValue(value, newFiltersByMealType);
  },
  target: $filtersByMealType,
});

sample({
  clock: handleFiltersReset,
  filter: (type) => type === EFilterType.Room,
  fn: () => {
    return [CHECKED_ALL];
  },
  target: $filtersByRoomType,
});

sample({
  clock: handleFiltersReset,
  filter: (type) => type === EFilterType.Meal,
  fn: () => {
    return [CHECKED_ALL];
  },
  target: $filtersByMealType,
});

sample({
  clock: handleFiltersReset,
  filter: (type) => !type,
  fn: () => {
    return [CHECKED_ALL];
  },
  target: [$filtersByRoomType, $filtersByMealType],
});

export const $toursCombinedAsRooms = $toursData.map(
  (data) => {
    return (
      data?.data
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        ?.filter((item) => item?.room?.id)
        .reduce<Record<string, TToursCombinedAsRooms[]>>((acc, val) => {
          if (val.room.id) {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            acc[val.room.id] = acc[val.room.id] || [];
            acc[val.room.id].push(val as unknown as TToursCombinedAsRooms);
          }

          return acc;
        }, {})
    );
  },
  {
    skipVoid: false,
  },
);

export const $roomsListByType = $toursData.map((data) => {
  const allRooms = {
    value: "all",
    label: "ALL_ROOM_TYPES",
  };

  if (!data?.data) {
    return [allRooms];
  }

  return uniqBy(
    [
      allRooms,
      ...data.data
        .filter(({ room }) => !!room)
        .map(({ room }) => ({
          value: room.id,
          label: room.name,
        })),
    ],
    "value",
  );
});

export const $mealListByType = $toursData.map((data) => {
  const allMeals = {
    value: CHECKED_ALL,
    label: "ALL_MEAL_TYPES",
  };

  if (!data?.data) {
    return [allMeals];
  }

  return uniqBy(
    [
      allMeals,
      ...data.data
        .filter(({ meal }) => !!meal)
        .map(({ meal }) => ({
          value: meal.id,
          label: meal.name,
        })),
    ],
    "value",
  );
});

export const $filteredToursCombinedAsRooms = combine(
  $toursCombinedAsRooms,
  $filtersByRoomType,
  $filtersByMealType,
  (toursCombinedAsRooms, filtersByRoomType, filtersByMealType) => {
    if (
      filtersByRoomType.includes(CHECKED_ALL) &&
      filtersByMealType.includes(CHECKED_ALL)
    ) {
      return toursCombinedAsRooms;
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return Object.entries(toursCombinedAsRooms ?? {}).reduce<
      Record<string, TToursCombinedAsRooms[]>
    >((acc, [key, value]) => {
      const filteredValue = value.filter((item) => {
        if (!item.room.id || !item.meal.id) {
          return false;
        }

        return (
          (filtersByRoomType.includes(item.room.id) ||
            filtersByRoomType.includes(CHECKED_ALL)) &&
          //
          (filtersByMealType.includes(item.meal.id) ||
            filtersByMealType.includes(CHECKED_ALL))
        );
      });

      if (filteredValue.length > 0) {
        acc[key] = filteredValue;
      }

      return acc;
    }, {});
  },
  {
    skipVoid: false,
  },
);

export const $filteredTours = combine(
  $toursData,
  $searchHotelsQuery,
  (toursData, searchHotelsQuery) => {
    if (!searchHotelsQuery) {
      return toursData;
    }

    const filteredTours =
      toursData?.data?.filter((item) => {
        return item.hotel.name
          .toLowerCase()
          .includes(searchHotelsQuery.toLowerCase());
      }) ?? [];

    return {
      ...toursData,
      data: filteredTours,
      meta: {
        ...toursData?.meta,
        packages_total: filteredTours.length,
      },
    };
  },
);

const $searchBlockData = getConstructorPageQuery.$data.map(
  (data) =>
    (data?.content?.blocks?.find((block) => block.type === EBlocks.Search)
      ?.data as TEditorSearchResponse) || null,
);

export const $regionIds = $searchBlockData.map((data) => {
  const ids = data?.arrivalLocationId;

  if (!ids) {
    return null;
  }

  if (Array.isArray(ids)) {
    return ids;
  } else {
    return [ids];
  }
});

export const $searchDates = $searchBlockData.map((data) => {
  if (!data || !data.beginDateFrom || !data.beginDateTo) {
    return null;
  }

  if (data.beginDateFrom && data.beginDateTo) {
    return {
      from: data.beginDateFrom,
      to: data.beginDateTo,
    };
  }
});
