import { attach } from "effector";

import {
  TFFRequest,
  TPageMetadata,
  TSendLeadToCRMPayload,
} from "../../types/common";
import { TCountriesDetails } from "../../types/countries-details";
import { TSelection, THeadingCaption } from "../../types/selections";

import { TLocationsResponse } from "@/pagesLayer/polety/[tourId]/model/paymentTypes";

import { requestFx } from "@/shared/config";

type TGetCountriesDetailsbyIdResponse = TFFRequest<TCountriesDetails>;
type TGetOfficesListResponse = TFFRequest<TLocationsResponse>;
type TGetSeoMetaTagsResponse = TFFRequest<TPageMetadata>;
type TGetSelectionResponse = TFFRequest<TSelection>;

export const getCountriesDetailsByIdFx = attach<
  string,
  TGetCountriesDetailsbyIdResponse
>({
  effect: requestFx,
  mapParams: (code) => ({
    url: `countries/${code}`,
  }),
});

export const getOfficesListFx = attach<void, TGetOfficesListResponse>({
  effect: requestFx,
  mapParams: () => ({
    url: `offices`,
  }),
});

export const getSeoMetaTagsFx = attach<string, TGetSeoMetaTagsResponse>({
  effect: requestFx,
  mapParams: (url) => ({
    url: `seo/${url}`,
  }),
});

export const postLeadToCRMFx = attach<TSendLeadToCRMPayload, TFFRequest<void>>({
  effect: requestFx,
  mapParams: (data) => ({
    data,
    method: "post",
    url: `crm/lead`,
  }),
});

export const getSelectionDetailsFx = attach<string, TGetSelectionResponse>({
  effect: requestFx,
  mapParams: (code) => ({
    url: `selections/${code}`,
  }),
});

export const getHeadingCaptionsFx = attach<void, TFFRequest<THeadingCaption>>({
  effect: requestFx,
  mapParams: () => ({
    url: `header-blocks`,
  }),
});
